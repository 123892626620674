
import Button from "@/components/UI/Button.vue";
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup() {
    const userStore = useUser()
    return {userStore}
  },
  components: {Button},
  methods: {
    takeAction() {
      if (this.hasUnverified) {
        bus.emit('modals:phoneverify:show', this.userStore.profile.phoneNumbers.filter(p => !p.isVerified && p.canReceiveTexts)[0])
      } else {
        this.$router.push({name: 'user-settings-general'})
      }
    }
  },
  computed: {
    title(): string {
      if (this.hasUnverified) {
        return this.hasNoPhone ? 'Add A Phone Number' : 'Don\'t Forget To Verify Your Phone Number'
      } else {
        return this.hasNoPhone ? 'Add A Phone Number' : ''
      }
    },
    content(): string {
      if (this.hasUnverified) {
        return this.hasNoPhone ? 'Add a phone number to your account to receive text messages from your productions' :
            'Verify your number to start receiving texts from your productions'
      } else {
        return this.hasNoPhone ? 'Add a phone number to your account to receive text messages from your productions' :
            ''
      }
    },
    hasNoPhone(): boolean {
      return this.userStore.profile.phoneNumbers.length === 0
    },
    hasUnverified(): boolean {
      return this.userStore.profile.phoneNumbers.filter(p => !p.isVerified && p.canReceiveTexts).length > 0
    },
    isNewAccount() {
      return true
    },
    isVisible(): boolean {
      /**
       * Show only under one of these conditions:
       * - User has no phone numbers and account is less than 14 days old
       * - User only has unverified phone numbers that can receive texts
       */
      return this.hasNoPhone || this.hasUnverified
    },
  }
})
