
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {Invitation} from "@/models/user/Invitation";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  components: {Button},
  setup() {
    const userStore = useUser()
    return {userStore}
  },
  methods: {
    viewInvitation() {
      bus.emit('modals:invitations:view', this.userStore.incomingInvitations[0])
    }
  },
  computed: {
    isVisible(): boolean {
      return this.userStore.incomingInvitations.length > 0
    },
    invitation(): Invitation {
      return this.userStore.incomingInvitations[0]
    }
  }
})
