
import ScheduleWidget from "@/components/Calendar/ScheduleWidget.vue";
import {defineComponent} from "vue";
import AngleDown from "@/components/icons/navigation/AngleDown.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import moment from "moment";
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {getCommunicationType} from "@/models/enums/CommunicationType";
import {CommunicationService} from "@/services/CommunicationService";
import {CallService} from "@/services/CallService";
import {bus} from "@/modules/eventBus";
import AddPhoneAlert from "@/components/UI/alerts/AddPhoneAlert.vue";
import {EmailAddressService} from "@/services/EmailAddressService";
import Swal from "sweetalert2";
import {useUser} from "@/store/pinia/userStore";
import {useToast} from "vue-toastification";
import OnboardingTab from "@/components/User/OnboardingTab.vue";
import NewInivitationAlert from "@/components/UI/alerts/NewInivitationAlert.vue";
import {Invitation} from "@/models/user/Invitation";
import * as Sentry from "@sentry/browser";

export default defineComponent({
  setup() {
    const userStore = useUser();
    const toast = useToast();
    return {
      userStore,
      toast
    }
  },
  components: {
    NewInivitationAlert,
    OnboardingTab,
    AddPhoneAlert,
    Button,
    Selector2,
    AngleDown,
    ScheduleWidget
  },
  mounted() {
    // console.log('mounted User-Overview')
    if (this.userStore.outdatedTerms) {
      this.$router.push({name: 'terms'})
    } else {
      this.fetch()
      this.setPoll()
      if (document.location.search.indexOf('verify') > -1) {
        this.verifyEmail(document.location.search.split('verify=')[1])
      }
      bus.on('invitations:actions:accept', () => {
        this.fetch()
      });
      bus.on('modal:scheduling:updated', () => {
        this.fetch()
      })
    }
  },
  unmounted() {
    clearInterval(this.poll)
    this.poll = {}
  },
  methods: {
    setPoll() {
      if (process.env.VUE_APP_SH_ENV === 'Production') {
        this.poll = setInterval(this.fetch, 5000)
      }
    },
    fetch() {
      const start = moment().startOf('day');
      const end = moment().add(8, "days").endOf('day');

      CommunicationService.fetchAccountCommunications().then(response => {
        this.communications = response.data
      }).catch(err => {
        Sentry.captureException(err)
      }).finally(() => {
        this.communicationsLoading = false
      })
      CallService.fetchAccountCalls(start.format(), end.format()).then(response => {
        this.calls = response.data
        this.scheduleLoading = false
      }).catch(err => {
        Sentry.captureException(err)
      }).finally(() => {
        this.scheduleLoading = false
      })
    },
    verifyEmail(verificationKey: string) {
      EmailAddressService.verify(verificationKey).then(() => {
        Swal.fire('Email Verified!', 'Thanks for verifying your email. Any pending invitations that were sent to this email are now available for you to review and accept or decline.')
      })
    },
    invokeAddConflict() {
      bus.emit('modal:conflicts:add')
    },
    navigateToManageConflicts() {
      this.$router.push({name: 'user-settings-conflicts'})
    },
    getFriendlyDateTime(date) {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    navigateToCommunications() {
      this.$router.push({name: 'user-communications'})
    },
    getCommunicationType(type) {
      return getCommunicationType(type)
    },
    getShortFriendlyDateTime(date: Date): string {
      return moment(date).format('MMM Do h:mm a').toString();
    },
    getRelativeDateTime(date: Date): string {
      return moment(date).calendar();
    },
    viewCommunication(communication: CommunicationViewModel) {
      bus.emit('modal:communications:viewer:view', communication)
    },
    toggleRequestAccess() {
      bus.emit('modals:request:show')
    },
    goToSettings() {
      this.$router.push({name: 'user-settings-general'})
    },
    viewInvitation(invitation: Invitation) {
      bus.emit('modals:invitations:view', invitation)
    }
  },
  data() {
    return {
      communicationsFilter: 'Last 7 Days',
      communications: [] as Array<CommunicationViewModel>,
      calls: [] as Array<CallCalendarEvent>,
      scheduleLoading: true,
      communicationsLoading: true,
      poll: {} as any
    }
  },
  computed: {
    greeting() {
      const myDate = new Date();
      const hrs = myDate.getHours();
      let greet;
      if (hrs < 12)
        greet = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17)
        greet = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24)
        greet = 'Good Evening';
      return greet;
    },
    recentCommunications(): Array<CommunicationViewModel> {
      return this.communications.filter(c => {
        return moment(c.lastUpdated).isAfter(moment().subtract(7, "days").endOf('day'))
      })
    },
  }
})
