
import {defineComponent, ref} from "vue";
import {Invitation} from "@/models/user/Invitation";
import Button from "@/components/UI/Button.vue";
import CreateOrganizationModal from "@/components/modals/CreateOrganizationModal.vue";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {bus} from "@/modules/eventBus";
import { useUser } from "@/store/pinia/userStore";

export default defineComponent({
  setup() {
    const createOrganizationModal = ref<InstanceType<typeof CreateOrganizationModal>>();
    const invokeCreateOrganization = () => {
      createOrganizationModal.value?.invoke()
    }
    const userStore = useUser();
    return {
      createOrganizationModal,
      invokeCreateOrganization,
      userStore
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({name: 'user-onboarding'})
    },
    toggleRequestAccess() {
      bus.emit('modals:request:show')
    },
    viewInvitation(invitation: Invitation) {
      bus.emit('modals:invitations:view', invitation)
    }
  },
  mounted() {
    bus.on('modals:request:complete', () => {
      this.userStore.fetchInvitations()
    })
  },
  components: {Button, CreateOrganizationModal},
  computed: {
    invitations(): Array<Invitation> {
      try {
        return this.userStore.invitations.filter(x => x.status === MembershipStatus.PendingUser)
      } catch {
        return new Array<Invitation>();
      }
    },
    requests(): Array<Invitation> {
      try {
        return this.userStore.invitations.filter(x => x.status === MembershipStatus.PendingEntity)
      } catch {
        return new Array<Invitation>();
      }
    },
  }
})
