import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center align-items-md-start" }
const _hoisted_2 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.takeAction && _ctx.takeAction(...args))),
        class: "cursor-pointer alert alert-dismissible bg-light-primary border border-primary border-dashed d-flex flex-column flex-sm-row p-5 mb-10"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
        ]),
        (_ctx.hasUnverified)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "d-none d-md-flex position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn-sm ms-sm-auto align-items-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Verify Now ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasNoPhone)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "d-none d-md-flex position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn-sm ms-sm-auto align-items-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Add Number ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}